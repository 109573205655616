import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © SeamlessSlot.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Designed & Developed by{' '}
                <a
                  href="https://seamlessideas.co.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ borderBottom: '1px solid' }}>
                  Seamless Ideas
                </a>{' '}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
