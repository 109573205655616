import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withTranslation } from 'react-i18next';

// Redux
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import withRouter from '../../Common/withRouter';

// users
//import user1 from '../../../assets/images/users/avatar-1.jpg';
import { useAuthStore } from '../../../zustand-store/auth.store';
import { logoutDev, logoutProd } from '../../../axios/login/login.api';

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const firstName = useAuthStore((state) => state.firstName);
  const lastName = useAuthStore((state) => state.lastName);
  const navigate = useNavigate();

  const logout = async () => {
    import.meta.env.VITE_APP_ENV === 'dev' ? await logoutDev() : await logoutProd();
    localStorage.removeItem('locationId');
    navigate('/login');
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          {/* <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" /> */}

          <span
            style={{ backgroundColor: '#000', color: '#fff' }}
            className="rounded-circle header-profile-user p-2">
            {firstName?.charAt(0)} {lastName?.charAt(0)}
          </span>

          <span className="d-none d-xl-inline-block ms-2 me-1">{firstName + ' ' + lastName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t('My Wallet')}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t('Settings')}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t('Lock screen')}
          </DropdownItem> */}

          <DropdownItem
            tag="a"
            // href="/profile"
            // onClick={() => {
            //   navigate('/profile');
            // }}
          >
            {' '}
            <Link to="/profile" className=" ">
              <i className="bx bx-group font-size-16 align-middle me-1 "></i>{' '}
              <span className="text-dark">{props.t('Profile')}</span>
            </Link>
          </DropdownItem>
          <DropdownItem onClick={logout} className="dropdown-item" role="button">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span className="text-danger">{props.t('Logout')}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));
