import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardText, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import DetailModal from './googletagMangerDetailModal';
import { checkISConnected } from '../../axios/googleTagManager/index.api';
import { useAuthStore } from '../../zustand-store/auth.store';
const GoogleTagManagerCard = ({ title, description, icon }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isSquareIntegrated, setIsGTMIntegarted] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const locationId = useAuthStore((state) => state.locationId);
  useEffect(() => {
    checkGTMConnected(locationId);
  }, [locationId, modalOpen]);
  const checkGTMConnected = async (locationId) => {
    setLoader(true);
    let response = await checkISConnected(locationId);
    if (response.status === 200) {
      setIsGTMIntegarted(response?.data?.data);

      setLoader(false);
    }
  };
  return (
    <>
      <Card
        style={{ border: '1px solid', color: '#EFF2F7', cursor: 'pointer', height: 220 }}
        onClick={toggleModal} // open modal on card click
      >
        <CardBody>
          <div className="d-flex justify-content-between">
            <img style={{ width: '30px', height: '30px' }} src={icon} alt="" />
            {loader ? (
              <span className="text-warning">Loading...</span>
            ) : (
              <>
                {isSquareIntegrated ? (
                  <Button className="btn btn-success rounded-pill">
                    <i className="mdi mdi-check" /> Connected
                  </Button>
                ) : (
                  <span className="text-primary">Please connect</span>
                )}
              </>
            )}
          </div>
          <p style={{ fontSize: '20px', color: '#495057' }} className="mt-2">
            {title}
          </p>
          <CardText style={{ color: '#74788D' }}>{description}</CardText>
        </CardBody>
      </Card>
      {/* Modal Component */}
      <DetailModal
        isOpen={modalOpen}
        toggle={toggleModal}
        title={title}
        icon={icon}
        description={description}
      />
    </>
  );
};

GoogleTagManagerCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isIntegrated: PropTypes.string,
  icon: PropTypes.any // replace 'any' with the actual prop type
};

export default GoogleTagManagerCard;
