import PropTypes from 'prop-types';
import React from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { authProtectedRoutes, publicRoutes } from './routes';
import NonAuthLayout from './components/NonAuthLayout';
// Import scss
import './assets/scss/theme.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PrivateRoute } from './lib/PrivateRoute';

import { useAuthStore } from './zustand-store/auth.store';
import './index.css';
import NotFound from './pages/NotFound';
// Activating fake backend
//fakeBackend();

// const firebaseConfig = {
//   apiKey: import.meta.env.VITE_APP_APIKEY,
//   authDomain: import.meta.env.VITE_APP_AUTHDOMAIN,
//   databaseURL: import.meta.env.VITE_APP_DATABASEURL,
//   projectId: import.meta.env.VITE_APP_PROJECTID,
//   storageBucket: import.meta.env.VITE_APP_STORAGEBUCKET,
//   messagingSenderId: import.meta.env.VITE_APP_MESSAGINGSENDERID,
//   appId: import.meta.env.VITE_APP_APPID,
//   measurementId: import.meta.env.VITE_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = () => {
  // const selectLayoutState = (state) => state.Layout;
  // const refreshAllTheToken = useAuthStore((state) => state.refreshAllTheToken);
  const refreshAllTheToken = useAuthStore((state) => state.refreshAllTheToken);
  const accessToken = useAuthStore((state) => state.accessToken);

  const location = useLocation();
  const navigate = useNavigate();

  // const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
  //   layoutType: layout.layoutType
  // }));

  // const { layoutType } = useSelector(LayoutProperties);

  // function getLayout(layoutType) {
  //   let layoutCls = VerticalLayout;
  //   switch (layoutType) {
  //     case 'horizontal':
  //       layoutCls = HorizontalLayout;
  //       break;
  //     default:
  //       layoutCls = VerticalLayout;
  //       break;
  //   }
  //   return layoutCls;
  // }

  //const Layout = VerticalLayout;

  React.useEffect(() => {
    refreshAllTheToken();
    localStorage.setItem('path', location.pathname);
  }, []);

  React.useEffect(() => {
    const validPaths = [
      '/callback',
      '/cancellationPolicy',
      '/appointment',
      '/patientInfo',
      '/summary'
    ];
    if (accessToken !== '' && !validPaths.includes(localStorage.getItem('path'))) {
      navigate(localStorage.getItem('path'));
    }
  }, [accessToken]);

  return (
    <React.Fragment>
      <ToastContainer />
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => {
          return (
            <Route
              path={route.path}
              element={<PrivateRoute roles={route.role}>{route.component}</PrivateRoute>}
              key={idx}
              exact={true}
            />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout
  };
};

export default connect(mapStateToProps, null)(App);
