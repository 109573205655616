import React from 'react';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { ROLES } from '../constants/layout';
import UserProfile from '../pages/Authentication/user-profile';
import PaymentIntegrations from '../pages/PaymentSettings/components/PaymentIntegrations';
import TermsAndCondition from '../pages/TermsAndCondition';
const Login = React.lazy(() => import('../pages/Authentication/Login'));
const Logout = React.lazy(() => import('../pages/Authentication/Logout'));
const ForgetPwd = React.lazy(() => import('../pages/Authentication/ForgetPassword'));
const Dashboard = lazy(async () => await import('../pages/Dashboard/index'));
const Appointment = lazy(async () => await import('../pages/Appointment/index'));
const PatientInfo = lazy(async () => await import('../pages/PatientInfo'));
const CardPayment = lazy(async () => await import('../pages/CardPayment'));
const AppointmentSummary = lazy(async () => await import('../pages/AppointmentSummary'));
const Calendar = lazy(async () => await import('../pages/Calendar'));
const Patients = lazy(async () => await import('../pages/Patients'));
const Services = lazy(async () => await import('../pages/Services'));
const PatientDetail = lazy(async () => await import('../pages/PatientDetail'));
const BussinessSettings = lazy(async () => await import('../pages/BussinessSettings'));
const AppointmentAdmin = lazy(async () => await import('../pages/Appointment-Admin'));
const AppointmentAdminDetail = lazy(async () => await import('../pages/AdminAppointmentDetails'));
const AppointmentSettings = lazy(async () => await import('../pages/AppointmentSettings'));
const Locations = lazy(async () => await import('../pages/Locations'));
const Business = lazy(async () => await import('../pages/business'));
const PaymentSettings = lazy(async () => await import('../pages/PaymentSettings'));
const Callback = lazy(async () => await import('../pages/SquarePay-Auth-Callback/callback'));
const Admins = lazy(async () => await import('../pages/Admins'));
const Integrations = lazy(async () => await import('../pages/Integrations'));
const Cancellation = lazy(async () => await import('../pages/cancellationPolicy'));
const BusinessDetail = lazy(async () => await import('../pages/businessDetail'));
const PaymentLogs = lazy(async () => await import('../pages/PaymentsLogs'));
const Payments = lazy(async () => await import('../pages/Payments'));
const Refunds = lazy(async () => await import('../pages/Refunds'));
const authProtectedRoutes = [
  { path: '/dashboard', role: [ROLES.SUPER_ADMIN, ROLES.ADMIN], component: <Dashboard /> },
  { path: '/calender', role: [ROLES.SUPER_ADMIN, ROLES.ADMIN], component: <Calendar /> },
  { path: '/patients', role: [ROLES.SUPER_ADMIN, ROLES.ADMIN], component: <Patients /> },
  {
    path: '/adminAppointment',
    role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    component: <AppointmentAdmin />
  },
  {
    path: '/adminAppointment/:id',
    role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    component: <AppointmentAdminDetail />
  },
  { path: '/services', role: [ROLES.SUPER_ADMIN, ROLES.ADMIN], component: <Services /> },
  { path: '/paymentlogs', role: [ROLES.SUPER_ADMIN, ROLES.ADMIN], component: <PaymentLogs /> },
  { path: '/patients/:id', role: [ROLES.SUPER_ADMIN, ROLES.ADMIN], component: <PatientDetail /> },
  {
    path: '/business/:id',
    role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    component: <BusinessDetail />
  },
  {
    path: '/business-settings',
    role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    component: <BussinessSettings />
  },
  {
    path: '/appointment-settings',
    role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    component: <AppointmentSettings />
  },
  { path: '/locations', role: [ROLES.SUPER_ADMIN, ROLES.ADMIN], component: <Locations /> },
  { path: '/business', role: [ROLES.SUPER_ADMIN, ROLES.ADMIN], component: <Business /> },
  {
    path: '/payment-settings/*',
    role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    component: <PaymentSettings />
  },
  { path: '/refunds', role: [ROLES.SUPER_ADMIN, ROLES.ADMIN], component: <Refunds /> },
  //profile
  { path: '/profile', role: [ROLES.SUPER_ADMIN, ROLES.ADMIN], component: <UserProfile /> },
  { path: '/payments', role: [ROLES.SUPER_ADMIN, ROLES.ADMIN], component: <Payments /> },
  { path: '/users', role: [ROLES.SUPER_ADMIN], component: <Admins /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    component: <Navigate to="/calender" />
  },
  {
    path: '/integration-settings',
    exact: true,
    role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
    component: <PaymentIntegrations />
  },
  { path: '/integrations', role: [ROLES.SUPER_ADMIN], component: <Integrations /> }
];

const publicRoutes = [
  { path: '/appointment', component: <Appointment /> },
  { path: '/patientinfo', component: <PatientInfo /> },
  { path: '/cardpayment', component: <CardPayment /> },
  { path: '/summary', component: <AppointmentSummary /> },
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },
  { path: '/forgot-password', component: <ForgetPwd /> },
  { path: '/callback', component: <Callback /> },
  { path: '/cancellationPolicy', component: <Cancellation /> },
  { path: '/termsandconditions', component: <TermsAndCondition /> }
];

export { authProtectedRoutes, publicRoutes };
