import { create } from 'zustand';

export const useCustomerAppointmentStore = create((set) => ({
  location: [],
  businessList: [],
  selectedLocation: sessionStorage.getItem('loc_id')
    ? parseInt(sessionStorage.getItem('loc_id'))
    : null, //for leicster
  appointmentTypes: [
    // {
    //   type: 'Emergency Dental Consultation | Adult',
    //   time: '30min',
    //   cost: '£60'
    // },
    // {
    //   type: 'Emergency Dental Consultation | Child',
    //   time: '30min',
    //   cost: '£60'
    // }
  ],
  selectedAppointment: 0,
  refundModal: false,
  notificationList: [],
  notifications: [],
  businessName: null,
  setAllBusiness: (businessList) =>
    set((state) => {
      return {
        ...state,
        businessList
      };
    }),
  setAllLocations: (location) =>
    set((state) => {
      return {
        ...state,
        location
      };
    }),
  setAllNotifications: (notifications) =>
    set((state) => {
      return {
        ...state,
        notifications
      };
    }),
  setAllAppointments: (appointmentTypes) =>
    set((state) => {
      return {
        ...state,
        appointmentTypes
      };
    }),
  setRefundModal: (refundModal) =>
    set((state) => {
      return {
        ...state,
        refundModal
      };
    }),
  changeSelectedLocation: (selectedLocation) =>
    set((state) => {
      return {
        ...state,
        selectedLocation
      };
    }),
  changeBusinessName: (businessName) =>
    set((state) => {
      return {
        ...state,
        businessName
      };
    }),
  changeSelectedAppointmentType: (selectedAppointment) =>
    set((state) => {
      return {
        ...state,
        selectedAppointment
      };
    }),
  updateNotificationList: (newNotification) => {
    set((state) => {
      return {
        ...state,
        notificationList: [...state.notificationList, newNotification]
      };
    });
  },
  overRideNotificationList: (notificationList) => {
    set((state) => {
      return {
        ...state,
        notificationList
      };
    });
  }
}));
