import axios from 'axios';
import { CONSTANTS } from '../constants/utils';
import { refreshTokenDev, refreshTokenProd } from './login/login.api';
import { createBrowserRouter } from 'react-router-dom';
import { useAuthStore } from '../zustand-store/auth.store';
import { toast } from 'react-toastify';
const BASE_URL = import.meta.env.VITE_BASE_URL_BACKEND;

const router = createBrowserRouter(['/login']);
// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: BASE_URL, // Replace with your API base URL
  timeout: 10000, // Request timeout
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // You can add headers here, for example, adding an authorization token
    const token = useAuthStore.getState().accessToken;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error, '=error====');

    // Any status codes that falls outside the range of 2xx causes this function to trigger
    if (error.response) {
      if (error?.response?.data?.message != 'Token expired') {
        const message = error?.response?.data?.message;

        if (Array.isArray(message)) {
          // If the message is an array, display each message as a separate toast
          message.forEach((msg) => {
            if (typeof msg === 'string') {
              toast.error(msg);
            }
          });
        } else if (typeof message === 'string') {
          // If the message is a string, display it as a single toast
          toast.error(message);
        } else {
          // Fallback for unknown error formats
          toast.error('Something went wrong');
        }
        return error?.response?.data?.message || 'Something went wrong';
      }

      // The request was made and the server responded with a status code that falls out of the range of 2xx
      switch (error.response.status) {
        case 401: {
          const message = error.response.data.message;

          if (message === CONSTANTS.BASE_ERR_MESSAGES.INVALID_CREDITIONALS) {
            //show toast
          }

          if (message === CONSTANTS.BASE_ERR_MESSAGES.INVALID_TOKEN) {
            //show toast
          }

          if (message === CONSTANTS.BASE_ERR_MESSAGES.TOKEN_EXPIRED) {
            //implemet logic
            console.log('token expired and try to refresh token');
            try {
              const newAccessToken =
                import.meta.env.VITE_APP_ENV === 'dev'
                  ? await refreshTokenDev()
                  : await refreshTokenProd();

              console.log('This is the new AccessToken : ' + newAccessToken);

              const originalRequest = error.config;
              originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
              // Retry the original request
              return axios(originalRequest);
            } catch (refreshError) {
              // refresh token expired hence logout the user here
              console.log('Refresh Token Expired Logout User');
              router.navigate('/login', {});
              throw refreshError;
            }
          }
          break;
        }
        case 403:
          // Handle forbidden error
          console.log('Forbidden');
          break;
        case 404:
          // Handle not found error

          console.log('Not Found');
          break;
        case 500:
          // Handle server error
          console.log('Internal Server Error');
          break;
        default:
          // Handle other errors
          console.log('An error occurred', error.response.status);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.log('No response received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
