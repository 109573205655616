import { API } from '../api';
import axiosInstance from '../axiosConfig';

export const getGtmId = async (id) => {
  const response = await axiosInstance.get(API.GTM.GET_GTM_ID + id);
  return response;
};
export const addGtmInLocation = async (body) => {
  const response = await axiosInstance.post(`${API.GTM.ADD_GTM_ID}`, body);
  return response;
};
export const updateGtmInLocation = async (body) => {
  const response = await axiosInstance.patch(`${API.GTM.UPDATE_GTM_ID}`, body);
  return response;
};
export const checkISConnected = async (id) => {
  const response = await axiosInstance.get(`${API.GTM.IS_CONNECTED}${id}`);
  return response;
};
