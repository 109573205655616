import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Button, Label, Input, FormFeedback, Form } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import withRouter from '../../components/Common/withRouter';
import Breadcrumb from '../../components/Common/Breadcrumb';
import VerticalLayout from '../../components/VerticalLayout';
import { getProfile, updateProfile } from '../../axios/profile';
import { useAuthStore } from '../../zustand-store/auth.store';
import { toast } from 'react-toastify';

const UserProfile = () => {
  document.title = 'SeamlessSlot';
  const userId = useAuthStore((state) => state.id);
  const { updateUserData } = useAuthStore((state) => state);
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let response = await getProfile(userId);
    setData(response?.data?.data);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '' || data?.firstName,
      lastName: '' || data?.lastName,
      email: '' || data?.email,
      phoneNo: '' || data?.phoneNo,
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email format').required('Email is required'),
      phoneNo: Yup.string().required('Phone Number is required'),
      password: showPasswordFields
        ? Yup.string()
            .required('Password is required')
            .min(5, 'Password must be at least 8 characters long')
        : Yup.string().notRequired(),
      confirmPassword: showPasswordFields
        ? Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
        : Yup.string().notRequired()
    }),
    onSubmit: (values) => {
      update(values);
    }
  });

  const update = async (values) => {
    setLoader(true);
    const payload = {
      email: values?.email,
      firstName: values?.firstName,
      lastName: values?.lastName,
      id: userId,
      password: values?.password,
      phoneNo: values.phoneNo,
      confirmPassword: values?.confirmPassword
    };
    let response = await updateProfile(payload);
    if (response?.data?.message === 'success') {
      toast.success(response?.data?.data);
      setIsEditMode(false);
      getData();
      updateUserData({
        firstName: values?.firstName,
        lastName: values?.lastName
      });
    }
    setLoader(false);
  };

  const renderField = (label, value) => (
    <div className="mb-3">
      <Label className="form-label">{label}</Label>
      <Input type="text" value={value || ''} disabled />
    </div>
  );

  return (
    <VerticalLayout>
      <React.Fragment>
        <div className="page-content">
          <Container>
            <Breadcrumb title="SeamlessSlot" breadcrumbItem="Profile" />
            <Card>
              <CardBody>
                {!isEditMode ? (
                  <>
                    {renderField('Email', validation.values?.email)}
                    {renderField('First Name', validation.values?.firstName)}
                    {renderField('Last Name', validation.values?.lastName)}
                    {renderField('Phone Number', validation.values.phoneNo)}
                    <Button color="primary" onClick={() => setIsEditMode(true)}>
                      Edit Profile
                    </Button>
                  </>
                ) : (
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <div className="form-group">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        type="text"
                        disabled
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ''}
                        invalid={validation.touched.email && validation.errors.email ? true : false}
                      />
                      {validation.touched.email && validation.errors.email && (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      )}
                    </div>
                    <div className="form-group mt-3">
                      <Label className="form-label">First Name</Label>
                      <Input
                        name="firstName"
                        className="form-control"
                        placeholder="Enter First Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstName || ''}
                        invalid={
                          validation.touched.firstName && validation.errors.firstName ? true : false
                        }
                      />
                      {validation.touched.firstName && validation.errors.firstName && (
                        <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                      )}
                    </div>
                    <div className="form-group mt-3">
                      <Label className="form-label">Last Name</Label>
                      <Input
                        name="lastName"
                        className="form-control"
                        placeholder="Enter Last Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastName || ''}
                        invalid={
                          validation.touched.lastName && validation.errors.lastName ? true : false
                        }
                      />
                      {validation.touched.lastName && validation.errors.lastName && (
                        <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                      )}
                    </div>
                    <div className="form-group mt-3">
                      <Label className="form-label">Phone Number</Label>
                      <Input
                        name="phoneNo"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phoneNo || ''}
                        invalid={
                          validation.touched.phoneNo && validation.errors.phoneNo ? true : false
                        }
                      />
                      {validation.touched.phoneNo && validation.errors.phoneNo && (
                        <FormFeedback type="invalid">{validation.errors.phoneNo}</FormFeedback>
                      )}
                    </div>

                    {showPasswordFields && (
                      <>
                        <div className="form-group mt-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Enter Password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ''}
                            invalid={
                              validation.touched.password && validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password && validation.errors.password && (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          )}
                        </div>
                        <div className="form-group mt-3">
                          <Label className="form-label">Confirm Password</Label>
                          <Input
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Confirm Password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ''}
                            invalid={
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirmPassword &&
                            validation.errors.confirmPassword && (
                              <FormFeedback type="invalid">
                                {validation.errors.confirmPassword}
                              </FormFeedback>
                            )}
                        </div>
                      </>
                    )}
                    <div className="form-group mt-3">
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          id="togglePasswordUpdate"
                          className="form-check-input"
                          value={showPasswordFields}
                          onChange={() => setShowPasswordFields((prevState) => !prevState)}
                        />
                        <Label htmlFor="togglePasswordUpdate" className="form-check-label">
                          {showPasswordFields ? 'Cancel Password Update' : 'Update Password'}
                        </Label>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button type="submit" color="primary" className="me-2" disabled={loader}>
                        {loader ? 'Updating...' : 'Update'}
                      </Button>
                      <Button type="button" color="secondary" onClick={() => setIsEditMode(false)}>
                        Cancel
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </VerticalLayout>
  );
};

export default withRouter(UserProfile);
