import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
// i18n
import { withTranslation } from 'react-i18next';
import * as io from 'socket.io-client';
import { useCustomerAppointmentStore } from '../../../zustand-store/customerAppointment.store';
import { useAuthStore } from '../../../zustand-store/auth.store';
import {
  getNotificationByLocations,
  markAllNotifications
} from '../../../axios/notifications/index.js';
const socket = io.connect(import.meta.env.VITE_BASE_URL_BACKEND);

const NotificationDropdown = (props) => {
  const [menu, setMenu] = useState(false);
  const notifications = useCustomerAppointmentStore((state) => state.notifications);
  const updateNotificatiions = useCustomerAppointmentStore((state) => state.setAllNotifications);
  const overRideNotificationList = useCustomerAppointmentStore(
    (state) => state.overRideNotificationList
  );
  const locationId = useAuthStore((state) => state.locationId);
  // const [notifications,setNotifications]=useState([])
  const originalFaviconUrlRef = useRef('');
  const whenLocationChanged = () => {
    overRideNotificationList([]);
  };
  useEffect(() => {
    whenLocationChanged();
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      originalFaviconUrlRef.current = favicon.href;
    }

    // const unreadCount = notifications.filter((msg) => !msg.isRead).length;

    // updateFavicon(unreadCount > 0);

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    socket.on(`PUBLIC_APPOINTMENT_CREATED`, (data) => {
      console.log('Received message:', data);
      getNotifications();
    });

    return () => {
      socket.off(`locationId:${locationId}`);
    };
  }, [locationId]);

  useEffect(() => {
    getNotifications();
  }, [locationId]);
  const getNotifications = async () => {
    let data = await getNotificationByLocations(locationId);
    updateNotificatiions(data?.data);
  };
  useEffect(() => {
    const unreadCount = notifications.filter((msg) => !msg.isRead).length;
    updateFavicon(unreadCount > 0);
  }, [notifications]);

  const updateFavicon = (hasNotification) => {
    const favicon = document.querySelector('link[rel="icon"]');

    if (!favicon || !originalFaviconUrlRef.current) return;

    if (!hasNotification) {
      favicon.href = originalFaviconUrlRef.current;
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      // Draw the original favicon on the canvas
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);

      // Calculate the position of the red dot
      const dotRadius = img.width * 0.2; // Size of the dot relative to favicon size
      const xOffset = img.width - dotRadius - 2; // Adjusted X coordinate for the dot
      const yOffset = dotRadius + 2; // Adjusted Y coordinate for the dot

      // Draw a red dot above the logo and slightly to the right
      context.beginPath();
      context.arc(xOffset, yOffset, dotRadius, 0, 2 * Math.PI);
      context.fillStyle = 'red';
      context.fill();

      // Set the canvas data as the new favicon
      favicon.href = canvas.toDataURL('image/png');
    };

    img.src = originalFaviconUrlRef.current;
  };

  function calculateTimeDifferenceFromNow(dateString) {
    // Parse the given date string and convert it to a Date object
    const givenDate = new Date(dateString);

    // Get the current date and time
    const now = new Date();

    // Calculate the difference in milliseconds
    const differenceInMillis = now - givenDate;

    // Convert milliseconds to minutes, hours, and days
    const differenceInMinutes = Math.floor(differenceInMillis / (1000 * 60));
    const differenceInHours = Math.floor(differenceInMillis / (1000 * 60 * 60));
    const differenceInDays = Math.floor(differenceInMillis / (1000 * 60 * 60 * 24));

    // Determine the appropriate unit to use
    if (differenceInDays > 0) {
      return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
    } else if (differenceInHours > 0) {
      return `${differenceInHours} hour${differenceInHours > 1 ? 's' : ''} ago`;
    } else if (differenceInMinutes > 0) {
      return `${differenceInMinutes} minute${differenceInMinutes > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now'; // Handle the case where the difference is less than a minute
    }
  }
  const readNotification = async (id) => {
    let data = await markAllNotifications({ ids: [id] });

    if (data?.message == 'success') {
      setMenu(!menu);
      getNotifications();
    }
  };
  const readyAll = async () => {
    const ids = notifications.map((appointment) => appointment.id);
    let data = await markAllNotifications({ ids: ids });

    if (data?.message == 'success') {
      setMenu(!menu);
      getNotifications();
    }
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => {
          setMenu(!menu);
          // if (menu) {
          //   readNotification();
          // }
        }}
        className="dropdown d-inline-block"
        tag="li">
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown">
          <i
            className={`bx bx-bell ${notifications.filter((msg) => !msg.isRead).length > 0 && 'bx-tada'}`}
          />
          {notifications.filter((msg) => !msg.isRead).length > 0 && (
            <span className="badge bg-danger rounded-pill">
              {notifications.filter((msg) => !msg.isRead).length}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t('Notifications')} </h6>
              </Col>
              {/* <div className="col-auto">
                <a href="#!" className="small">
                  {' '}
                  View All
                </a>
              </div> */}
            </Row>
          </div>

          <SimpleBar style={{ height: '230px' }}>
            {notifications.map((item, index) => (
              <div
                to=""
                className={`text-reset notification-item ${item.isRead ? 'read' : 'unread'}`}
                key={index}
                onClick={() => {
                  if (item?.isRead == false) {
                    readNotification(item?.id);
                  }
                }}>
                <div className="d-flex">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="bx bx-calendar" />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">{item?.customerName}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{item?.title}</p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />
                        {calculateTimeDifferenceFromNow(item?.bookingTime)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </SimpleBar>
          <div
            className="p-2 border-top d-grid"
            onClick={() => {
              readyAll();
            }}>
            <div className="btn btn-sm btn-link font-size-14 btn-block text-center" to="#">
              <i className="mdi mdi-arrow-right-circle me-1"></i> Mark all as read
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};
