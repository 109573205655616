import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../../src/assets/images/notFound.json'; // Adjust the path to your animation JSON file
import { Link } from 'react-router-dom';
const NotFound = () => {
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Lottie animationData={animationData} style={{ width: '100%', height: '80vh' }} />
      </div>
      <Link
        to={'/calender'}
        className="fs-1 fw-bold text-primary text-decoration-underline text-center z-3 hand ">
        <p className="text-center">Go back</p>
      </Link>

      {/* <p className='fs-1 fw-bold text-primary text-decoration-underline text-center z-3 hand ' onClick={()=>{
                    
                      navigate(-1)

                     }} >Go back</p> */}
    </div>
  );
};

export default NotFound;
