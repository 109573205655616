import axiosInstance from '../axiosConfig';
import { API } from '../api';
import { useCustomerAppointmentStore } from '../../zustand-store/customerAppointment.store';
export const getAllBusiness = async () => {
  const response = await axiosInstance.get(API.BUSINESS.GET);
  useCustomerAppointmentStore.getState().setAllBusiness(response?.data?.data);
  return response?.data?.data;
};
export const createNewBusiness = async (body) => {
  const response = await axiosInstance.post(API.BUSINESS.ADD, body);
  return response;
};

export const updateNewBusiness = async (body) => {
  const response = await axiosInstance.patch(API.BUSINESS.UPDATE, body);
  return response;
};

export const getBusinessByLocation = async (id) => {
  const response = await axiosInstance.get(API.BUSINESS.BY_ID + id);
  return response;
};

export const deleteBusiness = async (id) => {
  const response = await axiosInstance.delete(API.BUSINESS.DELETE + id);
  return response;
};
